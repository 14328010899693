<template>
	<div class="navbars">
		<div class="row">
			<div class="small-10 small-offset-1 medium-8 medium-offset-2 end columns">
				<div class="navStepsWrap">
					<ol class="navSteps">
						<li v-bind:class="{ active: (1 < state || autoIsFirst === true) }" v-if="prods.indexOf('Auto') != -1">
							<icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'navStepsAuto show-for-medium ' + carIconClass"></icon-helper>
							<span class="navStepLabel hide-for-medium">Auto</span>
						</li>
						<li v-bind:class="{ active: (2 < state || homeIsFirst === true) }" v-if="prods.indexOf('Home') != -1">
							<icon-helper :glyph="'home'" :variant="iconvariant" :classes="'navStepsHome show-for-medium ' + houseIconClass"></icon-helper>
							<span class="navStepLabel hide-for-medium">Home</span>
						</li>
					</ol>
				</div>
			</div>
		</div>
		<div class="subnav-wrap">
			<div class="row">
				<div class="small-12 columns">
					<div class="subnav-content" v-bind:class="{ 'withProductIcons': (hasMultipleProducts === true), 'singleProduct': (hasMultipleProducts === false) }">
						<div class="subnav-preIcons" v-bind:class="{ 'double': hasMultipleProducts === true && homeIsShown === true }">
							<div class="subnav-prodicon" v-show="prods.indexOf('Auto') != -1">
								<icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'navStepsAuto ' + carIconClass"></icon-helper>
							</div>
							<div class="subnav-prodicon" v-show="homeIsShown">
								<icon-helper :glyph="'home'" :variant="iconvariant" :classes="'navStepsHome ' + houseIconClass"></icon-helper>
							</div>
						</div>
						<ol class="subnav three-part subnav-Auto" v-show="autoIsShown">
							<li role="progressbar" class="active">{{ tabLabels.Auto[0] }}</li>
							<li role="progressbar" v-bind:class="{ active: ((state > 1) && (1 < substate)) }">{{ tabLabels.Auto[1] }}</li>
							<li role="progressbar" v-bind:class="{ active: ((state > 1) && (2 < substate)) }">{{ tabLabels.Auto[2] }}</li>
						</ol>
						<ol class="subnav four-part subnav-Home" v-show="homeIsShown">
							<li role="progressbar" class="active">{{ tabLabels.Home[0] }}</li>
							<li role="progressbar" v-bind:class="{ active: ((state > 1) && (1 < substate)) }">{{ tabLabels.Home[1] }}</li>
							<li role="progressbar" v-bind:class="{ active: ((state > 1) && (2 < substate)) }">{{ tabLabels.Home[2] }}</li>
							<li role="progressbar" v-bind:class="{ active: ((state > 1) && (3 < substate)) }">{{ tabLabels.Home[3] }}</li>
						</ol>
						<div class="subnav-postIcons" v-if="hasMultipleProducts" v-show="autoIsShown">
							<div class="subnav-prodicon">
								<icon-helper :glyph="'home'" :variant="iconvariant" :classes="'navStepsHome ' + houseIconClass"></icon-helper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		prods: {
			type: Array, 
			required: true
		}, 
		state: {
			type: Number, 
			required: true
		}, 
		substate: {
			type: Number, 
			required: true
		}, 
		iconvariant: {
			type: String, 
			default: 'wave1'
		}, 
		autolabels: {
			type: Array, 
			required: false
		}, 
		homelabels: {
			type: Array, 
			required: false
		}
	}, 
	data: function() {
		return {
			assetRoot: 'cdn/'
		}
	}, 
	computed: {
		hasMultipleProducts: function() {
			return (this.prods.length > 1);
		}, 
		autoIsFirst: function() {
			return (this.prods.indexOf('Auto') > -1);
		}, 
		homeIsFirst: function() {
			return (this.prods.indexOf('Auto') == -1 && this.prods.indexOf('Home') > -1);
		}, 
		autoIsShown: function() {
			return (this.state == 2 || (this.state == 1 && (this.substate == 1 || this.substate == 2) && this.autoIsFirst === true));
		}, 
		homeIsShown: function() {
			return (this.state == 3 || (this.state == 1 && (this.substate == 1 || this.substate == 2) && this.homeIsFirst === true));
		}, 
		carIconClass: function() {
			return (1 < this.state || this.autoIsFirst === true) ? 'producticon-active' : 'producticon-inactive';
		}, 
		houseIconClass: function() {
			return (2 < this.state || this.homeIsFirst === true) ? 'producticon-active' : 'producticon-inactive';
		}, 
		bundleIconClass: function() {
			return (99 < this.state) ? 'producticon-active' : 'producticon-inactive';
		},
		tabLabels: function() {
			let out = {
				'Auto': ['Drivers', 'Vehicles', 'History'], 
				'Home': ['Basics', 'Structure', 'Lifestyle', 'History']
			};
			if (window.is.array(this.autolabels) === true) {
				if ((typeof this.autolabels[0]) == 'string' && this.autolabels[0].length > 0) { out.Auto[0] = this.autolabels[0]; }
				if ((typeof this.autolabels[1]) == 'string' && this.autolabels[1].length > 0) { out.Auto[1] = this.autolabels[1]; }
				if ((typeof this.autolabels[2]) == 'string' && this.autolabels[2].length > 0) { out.Auto[2] = this.autolabels[2]; }
				
			}
			if (window.is.array(this.homelabels) === true) {
				if ((typeof this.homelabels[0]) == 'string' && this.homelabels[0].length > 0) { out.Home[0] = this.homelabels[0]; }
				if ((typeof this.homelabels[1]) == 'string' && this.homelabels[1].length > 0) { out.Home[1] = this.homelabels[1]; }
				if ((typeof this.homelabels[2]) == 'string' && this.homelabels[2].length > 0) { out.Home[2] = this.homelabels[2]; }
				if ((typeof this.homelabels[3]) == 'string' && this.homelabels[3].length > 0) { out.Home[3] = this.homelabels[3]; }
			}
			return out;
		}
	}, 
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		}
	}
}
</script>